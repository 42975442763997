
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import { getSession } from "next-auth/react";
import { SignInForm } from "@/containers/auth/signin/form";
import { withErrorHandling } from "@/utils/handleServerSideErrors";
const index = ({ ip }) => {
    return <SignInForm ip={ip}/>;
};
const getServerSideProps = withErrorHandling(async (context) => {
    const session = await getSession(context);
    const ip = context.req.headers["x-forwarded-for"];
    let first_access = session?.user?.change_password_in_acccess;
    if (first_access) {
        return {
            redirect: {
                destination: "/auth/reset",
                permanent: false
            }
        };
    }
    return {
        props: {
            ip
        }
    };
});
export default index;

    async function __Next_Translate__getServerSideProps__1932afbec4b__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/signin/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1932afbec4b__ as getServerSideProps }
  